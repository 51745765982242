import Container    from '../../../components/Theme/Dashboard';
import Table    from '../../../components/Table';
import usePermissions from '../../../hooks/usePermissions';
import Input        from '../../../components/Input';
import { Button, Grid } from '@mui/material';
import { Fragment, useState } from 'react';
import useFormData from '../../../hooks/useFormData';
import useAxios from '../../../hooks/useAxios';
import { useNavigate } from "react-router-dom";


const td  = [
  {
    label:"Nombre",
    field:"producto_name",
    className:"",
    align:"left",
  },

  {
    label:"Categoría",
    field:"label",
    className:"",
    align:"left",
  },

  {
    label:"Etiquetas",
    field:"tags",
    className:"",
    align:"left",
  },

  {
    label:"Precio",
    field:"price",
    className:"",
    align:"left",
  },
  
  {
    label:"Acción",
    field:"event",
    className:"",
    align:"center",
    event:[
      {
        label:"Ver",
        action:"view",
        byIndex:{
                  prefixed:"./productos",
                  key:"id"
                },
      },
    ]
  },
]

const Home=(props)=>{
  const navigate                    =   useNavigate();
  const axios                       =   useAxios();
  const { formData,    onChange }   =   useFormData();
  const permissions                 =   usePermissions("clientes","index",{
                                                                component:Table,
                                                                td:td,
                                                                href:"0"                                                                
  
                                                              });
  const [newScrap,setNewScrap]      =   useState(false)

  const onSubmit=(e)=>{
    e.preventDefault() 
    axios.getData({...formData},"api/v1/scrapMppromocionalesXURL?url="+formData.url).then((response)=>{
      if (response&&response.data&&response.data.product&&response.data.product.id) {
        return navigate("/dashboard/productos/productos-"+response.data.product.id);        
      }
    })    
  }

  return  <Container>
            <Grid alignContent={"right"} justifyContent={"right"} sx={{mb:2}}>
              {
                !newScrap&&(
                  <Fragment>
                    <Button sx={{mr:1,mt:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap2cdopromocionales?refresh=true"} variant='contained' target='_blank'>
                      Reiniciar Todo
                    </Button>              
                    <Button sx={{mr:1,mt:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/paso1"} variant='contained' target='_blank'>
                      Cargar Categorías
                    </Button>              
                    <Button sx={{mr:1,mt:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap2cdopromocionales"} variant='contained' target='_blank'>
                      Paso 1 Promocionales
                    </Button>
                    <Button sx={{mr:1,mt:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap2promoopcioncolombia"} variant='contained' target='_blank'>
                      Paso 1 Promoopcioncolombia
                    </Button>
                    <Button sx={{mr:1,mt:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap2mppromocionales"} variant='contained' target='_blank'>
                      Paso 1 Mppromocionales
                    </Button>
                    <Button sx={{mr:1,mt:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap3cdopromocionales"} variant='contained' target='_blank'>
                      Paso 2 Promocionales
                    </Button>
                    <Button sx={{mr:1,mt:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap3promoopcioncolombia"} variant='contained' target='_blank'>
                      Paso 2 Promoopcioncolombia
                    </Button>
                    <Button sx={{mr:1,mt:1}} component="a" href={document.location.origin.replace("3000","8000").replace("app","backend")+"/scrap3mppromocionales"} variant='contained' target='_blank'>
                      Paso 2 Mppromocionales
                    </Button>
                    <Button sx={{mr:1,mt:1}} variant='contained' onClick={()=>{setNewScrap(true)}}>
                      Scrap Individual
                    </Button>
                  </Fragment>
                )
              }
              {
                newScrap&&(
                  <Fragment>
                    <Button sx={{mr:1,mt:1}} variant='contained' onClick={()=>{setNewScrap(false)}}>
                      Cancelar Individual
                    </Button>
                  </Fragment>
                )
              }
            </Grid>
            {
              !newScrap?<Fragment>{permissions.render()}</Fragment>:<Fragment>
                <Grid>      
                  <form onSubmit={onSubmit}>
                    <Input defaultValue={""} required onChange={onChange} size="small" fullWidth name="url" type="text" label="Url"/>
                  </form>            
                </Grid>
              </Fragment>              
            }
          </Container>
}
export default Home
